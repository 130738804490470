import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import SelectedWorkLink from '../SelectedWorkLink'
import Footer from '../Footer';
import getDocumentHeight from '../functions.js'
import Loader from '../Loader.js'
import BackgroundImage from '../BackgroundImage.js'

const Project = props => {

	const projectsPerPage = 5
	const scrollLoadOffset = 500

	const slug = useParams().slug

	const [ project, setProject ] = useState(0)
	const [ numLoaded, setNumLoaded ] = useState(projectsPerPage)
	const [ firstImageLoaded, setFirstImageLoaded ] = useState(false)


	// Get data
	useEffect(() => {

	  (async () => {
	    let resp = await fetch('https://shannonjager.com/cms/')
	    let json = await resp.json()

	    let projects = json.projects
		for(let i = 0; i < projects.length; i++) {

			let urlParts = projects[i].url.split('/')
			if(urlParts.length > 1) {

				let projectSlug = urlParts[urlParts.length - 1]
				if(decodeURIComponent(projectSlug).replace(/[^a-zA-Z ]/g, '') === slug.replace(/[^a-zA-Z ]/g, '')) {

					setProject(projects[i])
					break
				}
			}
		}
	  })()

	}, [ ])


	// Show more on scroll
	const onScroll = () => {
	  if(window.scrollY + window.innerHeight > getDocumentHeight() - scrollLoadOffset) {
	    setNumLoaded(numLoaded + projectsPerPage)
	  }
	}
	useEffect(() => {
	  window.addEventListener('scroll', onScroll, { passive: true })
	  return () => window.removeEventListener('scroll', onScroll)
	})


	// Create rows of images (2x2 counts as 1 row)
	// Start at index 1 to avoid duplicating first hero image
	let rows = [ ]
	if(project && project.images) {
		for(let i = 1; i < Math.min(numLoaded, project.images.length); i += 5) {
		  	rows.push(
			    <div className="max-w-6xl mx-auto grid grid-cols-2 gap-2 mb-2" key={i}>
			    	<BackgroundImage classes="w-full pb-4x3" image={project.images[i]} />
			    	{ i + 1 < project.images.length ? (
			    		<BackgroundImage classes="w-full pb-4x3" image={project.images[i + 1]} />
		    		) : ''}
			    	{ i + 2 < project.images.length ? (
			    		<BackgroundImage classes="w-full pb-4x3" image={project.images[i + 2]} />
		    		) : ''}
			    	{ i + 3 < project.images.length ? (
			    		<BackgroundImage classes="w-full pb-4x3" image={project.images[i + 3]} />
		    		) : ''}
				</div>
			)

			if(i + 4 < project.images.length) {
				rows.push(
					<div className="mb-2">
						<BackgroundImage
							classes="
								w-[calc(100%+(theme(spacing.page-gutter)*2))] h-[calc(100vh-theme(spacing.header-height))] -mx-page-gutter
								sm:h-auto sm:pb-4x3
							"
							image={project.images[i + 4]}
						/>
					</div>
				)
			}
		}
	}

	return (
		<>
			<div className="pt-header-height p-page-gutter">
		      	<div className="min-h-screen relative">
		      		<Loader isVisible={!firstImageLoaded} />
		      		{(project && project.images) ? (
		      			<div>
							<BackgroundImage
								classes="
									w-[calc(100%+(theme(spacing.page-gutter)*2))] h-[calc(100vh-theme(spacing.header-height))] -mx-page-gutter
									sm:h-auto sm:pb-4x3
								"
								image={project.images[0]}
								load={() => setFirstImageLoaded(true)}
							/>
							<div className="max-w-6xl mx-auto py-12">
								<div className="grid grid-cols-3 sm:block">
									<p className="text-2xl sm:mb-8">
										<span>{project.title}</span>
										<span className="block -mt-1">{project.client_name}</span>
									</p>
									<div className="col-span-2">
										<div dangerouslySetInnerHTML={{__html: project.description.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2')}}></div>
										<a className="italic" href={project.project_url} target="_blank">{project.project_name || project.project_url}</a>
									</div>
								</div>
							</div>

							<div className="mb-16">
								{rows}
							</div>
						</div>
					) : ''}
				</div>

				<SelectedWorkLink />
			</div>
			<Footer />
		</>
	)
}

export default Project