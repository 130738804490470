import { useEffect, useState } from 'react'
import Slider from '../Slider'
import SelectedWorkLink from '../SelectedWorkLink'
import Footer from '../Footer'
import getDocumentHeight from '../functions.js'

const Home = () => {

	const projectsPerPage = 6
	const scrollLoadOffset = 500

	const [ projects, setProjects ] = useState([ ])
	const [ numLoaded, setNumLoaded ] = useState(projectsPerPage)


	// Get data
	useEffect(() => {

	  (async () => {
		let resp = await fetch('https://shannonjager.com/cms/')
		let json = await resp.json()

		setProjects(json.projects)
	  })()

	}, [ ])


	// Show more on scroll
	const onScroll = () => {
	  if(window.scrollY + window.innerHeight > getDocumentHeight() - scrollLoadOffset) {
		setNumLoaded(Math.min(projects.length, numLoaded + projectsPerPage))
	  }
	}
	useEffect(() => {
	  window.addEventListener('scroll', onScroll, { passive: true })
	  return () => window.removeEventListener('scroll', onScroll)
	})


	// Create rows of projects
	let rows = [ ]
	for(let i = 0; i < Math.max(Math.min(numLoaded, projects.length), 3); i += 3) {
	  rows.push(
		<div className="w-[1000px] mx-auto mb-16" key={i}>
		  <Slider project={projects[i]} centerPagination={false} link={true} />
		  {!projects[i] ? <div className="pb-16x9"></div> : null}
		</div>
	  )

	  if(projects[i + 1]) {
		rows.push(
		  <div className="grid grid-cols-2 gap-page-gutter mb-16" key={i + 1}>
		    <div>
		      <Slider project={projects[i + 1]} centerPagination={true} link={true} />
			  {!projects[i + 1] ? <div className="pb-16x9"></div> : null}
			</div>
			<div>
			  <Slider project={projects[i + 2]} centerPagination={true} link={true} />
			  {!projects[i + 2] ? <div className="pb-16x9"></div> : null}
		    </div>
		  </div>
		)
	  }
	}

	return (
	  <>
		<div className="pt-header-height p-page-gutter">
		  <div className="min-h-screen">
			{rows}
		  </div>
		  <SelectedWorkLink />
	  	</div>
		<Footer />
	  </>
	);
}

export default Home