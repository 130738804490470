import { useEffect, useState, useRef } from 'react'

const BackgroundImage = props => {

	const containerRef = useRef(null)

	const [ loaded, setLoaded ] = useState(false)
	const [ isVisible, setIsVisible ] = useState(false)

	useEffect(() => {
		const img = new Image()
		img.src = props.image
		if(props.load) {
			img.onload = props.load
		}
		setLoaded(true)
	}, [ props.load, props.image ])

	useEffect(() => {

	    const observer = new IntersectionObserver((entries) => {
	        const [ entry ] = entries
	        if(entry.isIntersecting) {
	            setIsVisible(true)
	        }
	    })

	    if(containerRef.current) {
	        observer.observe(containerRef.current)
	    }

	    return () => {
	        if(containerRef.current) {
	            observer.unobserve(containerRef.current)
	        }
	    }

	}, [ containerRef ])

	return (
		<div
			ref={containerRef}
			className={['background-image opacity-0 transition-opacity duration-1000 active:opacity-100', props.classes, loaded && isVisible ? 'active' : ''].join(' ')}
			style={{backgroundImage: loaded && isVisible ? 'url(' + props.image + ')' : null}}>
		</div>
	)
}

export default BackgroundImage