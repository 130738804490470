const getDocumentHeight = () => {
    return Math.max(
        document.documentElement['clientHeight'],
        document.body['scrollHeight'],
        document.documentElement['scrollHeight'],
        document.body['offsetHeight'],
        document.documentElement['offsetHeight']
    )
}

export default getDocumentHeight