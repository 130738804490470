const Footer = () => {
	return (
		<div className="flex justify-between border-t border-black pt-4 p-page-gutter sm:hidden">
			<div className="max-w-xl pr-1/2">
				Shannon Jager is a graphic designer from Calgary, Alberta, Canada, who is currently working as a Creative Director at BUCK.
			</div>
			<div className="text-right">
				<p className="text-left">
					Inquiries<br />
					<a href="mailto:shannon.jager@brut.us">shannon.jager@brut.us</a>
				</p>
			</div>
		</div>
	)
}

export default Footer