const Menu = props => {

	return (
		<div className={[
			'fixed z-menu-z-index top-header-height w-full h-[calc(100%-theme(spacing.header-height))]',
			'px-page-gutter py-4 bg-black text-white',
			!props.isOpen ? 'translate-x-full' : null
		].join(' ')}>
			<a href="/selected-work">
				<div className="h-1/3">
					<p className="border-t pt-1.5">Selected Work</p>
				</div>
			</a>
			<a href="/about">
				<div className="h-1/3">
					<p className="border-t pt-1.5">About</p>
				</div>
			</a>
			<a href="/contact">
				<div className="h-1/3">
					<p className="border-t pt-1.5">Contact</p>
				</div>
			</a>
		</div>
	)
}

export default Menu