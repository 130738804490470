const Loader = props => {
	return (
		<div className={[
			'absolute top-1/2 left-1/2 w-12 h-12 -m-6',
			'rounded-full animate-loader',
			!props.isVisible ? 'hidden' : '',
			!props.light ? 'bg-black' : 'bg-white',
			!props.z ? '-z-10' : props.z
		].join(' ')}></div>
	)
}

export default Loader