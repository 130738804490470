import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../Header'
import Footer from '../Footer'
import Menu from '../Menu'
import { useMediaQuery } from 'react-responsive'

const Layout = () => {

	const isMobile = useMediaQuery({ query: '(max-width: 800px)' })

	const [ isMenuOpen, setIsMenuOpen ] = useState(false)

	return (
		<>
		  <Header isMenuOpen={isMenuOpen} toggleMenu={() => { setIsMenuOpen(!isMenuOpen) }} />
		  {isMobile ? <Menu isOpen={isMenuOpen} /> : null}
		  <Outlet />
		</>
	)
}

export default Layout