import React from 'react';
import { useState, useRef, useEffect } from 'react'
import { Pagination } from 'swiper';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import BackgroundImage from './BackgroundImage'
import Loader from './Loader'

import 'swiper/css';
import 'swiper/css/navigation';

const Slider = props => {

    const containerRef = useRef(null)

    const [ currentSlide, setCurrentSlide ] = useState(1)
    const [ loaded, setLoaded ] = useState(false)
    const [ isVisible, setIsVisible ] = useState(false)

    useEffect(() => {

        const observer = new IntersectionObserver((entries) => {
            const [ entry ] = entries
            if(entry.isIntersecting) {
                setIsVisible(true)
            }
        })

        if(containerRef.current) {
            observer.observe(containerRef.current)
        }

        return () => {
            if(containerRef.current) {
                observer.unobserve(containerRef.current)
            }
        }

    }, [ containerRef ])

    let swiper
    if(props.project) {
        swiper = (
            <Swiper
                className="relative"
                modules={[ Navigation ]}
                loop
                navigation={{
                    nextEl: '.js_customNavigation--next',
                    prevEl: '.js_customNavigation--prev'
                }}
                onSlideChange={(swiper) => {
                    setCurrentSlide(swiper.realIndex + 1)
                }}
                onAfterInit={(swiper) => {
                    setLoaded(true)
                }}
            >
                {props.project.images ? props.project.images.map((image, i) => (
                    <SwiperSlide key={i}>
                        <BackgroundImage classes="pb-16x9" image={image} />
                    </SwiperSlide>
                )) : ''}
                <div className="js_customNavigation--prev absolute z-10 top-0 left-0 h-full w-1/3 cursor-arrow-left"></div>
                <div className="js_customNavigation--next absolute z-10 top-0 right-0 h-full w-1/3 cursor-arrow-right"></div>
            </Swiper>
        )

        if(props.link && loaded) {
            swiper = (
                <a href={props.project.url}>
                    {swiper}
                </a>
            )
        }
    }

    return (
        <div className="relative">
            <div className="absolute w-full pb-16x9"><Loader isVisible={!loaded || !isVisible} /></div>
            <div ref={containerRef} className={[
                'opacity-0 transition-opacity duration-1000 active:opacity-100',
                loaded && isVisible ? 'active' : ''
            ].join(' ')}>
                {props.project ? (
                    <>
                        {swiper}
                        <div className={[ 'grid mt-1', props.centerPagination ? 'grid-cols-3' : 'grid-cols-2' ].join(' ')}>
                            <p>
                                <span>{props.project.title}</span>
                                <span className="block -mt-1.5">{props.project.client_name}</span>
                            </p>
                            <div className={props.centerPagination ? 'text-center' : 'text-right'}>
                                <p className="js_customPagination leading-3 mt-1.5">
                                    <span className="js_customPagination--current">{currentSlide}</span>
                                    <span>/{props.project.images.length}</span>
                                </p>
                                <a href={props.project.url}>View Project</a>
                            </div>
                        </div>
                    </>
                ) : ''}
            </div>
        </div>
    );
};

export default Slider;