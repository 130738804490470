import plusIcon from './assets/images/plus.svg'

const Header = props => {
	return (
		<div className="
			fixed top-0 left-0 z-header-z-index
			w-full h-header-height
			grid grid-cols-2 items-center
			bg-white px-page-gutter
		">
			<div>
				<a href="/">Shannon Jager</a>
			</div>
			<div className="justify-self-end space-x-6 sm:hidden">
				<a href="/selected-work">Selected Work</a>
				<a href="/contact">Contact</a>
			</div>
			<div className="hidden sm:block justify-self-end self-stretch -mr-2" onClick={() => { props.toggleMenu() }}>
				<img className={[ 'h-full py-1.5', props.isMenuOpen ? 'rotate-45' : null ].join(' ')} src={plusIcon} />
			</div>
		</div>
	)
}

export default Header