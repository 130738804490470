const ContactLink = props => {
	let content = (
		<p>
			<span className="hidden group-hover:inline-block mr-1">→</span>
			{props.text}
		</p>
	)
	if(props.link) {
		content = <a className="group hover:text-red-600" href={props.link} target="_blank">{content}</a>
	}
	content = <div className="border-t border-white py-2.5">{content}</div>

	return content
}

export default ContactLink