import { useEffect, useState } from 'react'
import './App.css'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Layout from './pages/Layout'
import Home from './pages/Home'
import SelectedWork from './pages/SelectedWork'
import Contact from './pages/Contact'
import Project from './pages/Project'
import { useMediaQuery } from 'react-responsive'

const App = () => {

  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={!isMobile ? <Home /> : <SelectedWork />} />
          <Route path="selected-work" element={<SelectedWork />} />
          <Route path="project/:id/:slug" element={<Project />} />
          <Route path="contact" element={<Contact section="contact" />} />
          <Route path="about" element={<Contact section="about" />} />
          <Route path="clients" element={<Contact section="clients" />} />
          <Route path="press" element={<Contact section="press" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App