import { useEffect, useState } from 'react'
import Obfuscate from 'react-obfuscate'
import ContactLink from '../ContactLink'
import { useMediaQuery } from 'react-responsive'
import Loader from '../Loader'

const Contact = props => {

	const isMobile = useMediaQuery({ query: '(max-width: 800px)' })

	const [ contactInfo, setContactInfo ] = useState(0)

	// Get data
	useEffect(() => {

	  (async () => {
		let resp = await fetch('https://shannonjager.com/cms/')
		let json = await resp.json()

		json.contact_page.email = json.footer.email
		setContactInfo(json.contact_page)
	  })()

	}, [ ])

	const aboutContent = contactInfo.about ? (
		<>
			<p className="mb-8 sm:hidden">About</p>
			<div className="mb-8">{contactInfo.about}</div>
		</>
	) : null

	const emailContent = contactInfo.email ? (
		<>
			<p>Inquiries</p>
			<p className="mb-8"><Obfuscate email={contactInfo.email} /></p>
		</>
	) : null

	const instagramContent = contactInfo.instagram ? (
		<>
			<p>Instagram</p>
			<p><a href={'https://instagram.com/' + contactInfo.instagram.replace('@', '')} target="_blank">{contactInfo.instagram}</a></p>
		</>
	) : null

	const clientsContent = contactInfo.clients ? (
		<>
			<p className="mb-8 sm:hidden">Clients</p>
			<div>
				{contactInfo.clients.map((client, i) => (
					<div className="last:border-b" key={i}>
						<ContactLink text={client.text} link={client.link} />
					</div>
				))}
			</div>
		</>
	) : null

	const pressContent = contactInfo.press ? (
		<>
			<p className="mb-8 sm:hidden">Press</p>
			<div>
				{contactInfo.press.map((client, i) => (
					<div className="last:border-b" key={i}>
						<ContactLink text={client.text} link={client.link} />
					</div>
				))}
			</div>
		</>
	) : null

	const awardsContent = contactInfo.awards ? (
		<>
			<p className="my-8">Awards</p>
			<div>
				{contactInfo.awards ? contactInfo.awards.map((client, i) => (
					<div className="last:border-b" key={i}>
						<ContactLink text={client.text} link={client.link} />
					</div>
				)) : null}
			</div>
		</>
	) : null

	let content
	if(!isMobile) {
		content = (
			<>
				<div>
					{aboutContent}

					{emailContent}

					{instagramContent}
				</div>
				<div>
					{clientsContent}
				</div>
				<div>
					{pressContent}

					{awardsContent}
				</div>
			</>
		)
	} else {

		if(props.section == 'contact') {

			content = (
				<>
					<p className="mt-4 mb-8">Contact</p>

					{emailContent}

					{instagramContent}
				</>
			)
		} else {

			const tabBar = (
				<div className="flex space-x-4 mt-4 mb-8">
					<a href="/about"><p className={[ 'text-gray-400 active:text-white', props.section == 'about' ? 'active' : '' ].join(' ')}>About</p></a>
					<a href="/clients"><p className={[ 'text-gray-400 active:text-white', props.section == 'clients' ? 'active' : '' ].join(' ')}>Clients</p></a>
					<a href="/press"><p className={[ 'text-gray-400 active:text-white', props.section == 'press' ? 'active' : '' ].join(' ')}>Press</p></a>
				</div>
			)

			if(props.section == 'about') {
				content = (
					<>
						{tabBar}

						{aboutContent}

						{emailContent}

						{instagramContent}
					</>
				)
			} else if(props.section == 'clients') {
				content = (
					<>
						{tabBar}

						{clientsContent}
					</>
				)
			} else if(props.section == 'press') {
				content = (
					<>
						{tabBar}

						{pressContent}

						{awardsContent}
					</>
				)
			}
		}
	}

	return (
		<>
			<div className="
				w-full min-h-[calc(100vh-theme(spacing.header-height))]
				p-page-gutter pb-16 mt-header-height
				bg-black text-white
			">
				<Loader isVisible={!contactInfo} light={true} z={'z-0'} />
				<div className="grid grid-cols-3 gap-page-gutter sm:block">
					{content}
				</div>
			</div>
		</>
	)
}

export default Contact