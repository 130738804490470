import { useEffect, useState } from 'react'
import Footer from '../Footer';
import getDocumentHeight from '../functions.js'
import { useMediaQuery } from 'react-responsive'
import BackgroundImage from '../BackgroundImage.js'

const SelectedWork = () => {

	const isMobile = useMediaQuery({ query: '(max-width: 800px)' })

	const projectsPerPage = !isMobile ? 20 : 10
	const scrollLoadOffset = 500

	const [ projects, setProjects ] = useState(0)
	const [ numLoaded, setNumLoaded ] = useState(projectsPerPage)


	// Get data
	useEffect(() => {

	  (async () => {
	    let resp = await fetch('https://shannonjager.com/cms/')
	    let json = await resp.json()

	    setProjects(json.projects)
	  })()

	}, [ ])


	// Show more on scroll
	const onScroll = () => {
	  if(window.scrollY + window.innerHeight > getDocumentHeight() - scrollLoadOffset) {
	    setNumLoaded(numLoaded + projectsPerPage)
	  }
	}
	useEffect(() => {
	  window.addEventListener('scroll', onScroll, { passive: true })
	  return () => window.removeEventListener('scroll', onScroll)
	})

	let tempProjects = [ ]
	let imageCounter = 0
	for(let projectIndex = 0; projectIndex < projects.length; projectIndex++) {
		tempProjects.push(projects[projectIndex])
		imageCounter += projects[projectIndex].images.length
		if(imageCounter > numLoaded) {
			break
		}
	}

	let layout
	if(!isMobile) {
		layout = (
			<div className="grid grid-cols-5 gap-2 min-h-screen">
				{tempProjects ? tempProjects.map(project => project.images.map((image, i) => (
					<a href={project.url} className="group relative" key={i}>
						<div className="background-image pb-4x3" style={{ 'backgroundImage': 'url(' + image + ')' }}></div>
						<div className="
							absolute top-0 left-0 w-full h-full
							flex items-end
							bg-[#e6e6fa] text-black py-2 px-3
							opacity-0 transition-opacity duration-300 group-hover:opacity-100
						">
							<p>
								<span>{project.title}</span>
								<span className="block -mt-1.5">{project.client_name}</span>
							</p>
						</div>
					</a>
				))) : ''}
			</div>
		)
	} else {
		layout = [ ]
		if(tempProjects) {

			// Reduce array of projects to array of images with links
			let tempImages = tempProjects.reduce((currentImages, project) => {
				for(let i = 0; i < project.images.length; i++) {
					currentImages.push({ image: project.images[i], url: project.url })
				}
				return currentImages
			}, [ ])

			// Create rows of images (2x2 counts as 1 row)
			for(let i = 0; i < Math.min(numLoaded, tempImages.length); i += 5) {
			  	layout.push(
				    <div className="max-w-6xl mx-auto grid grid-cols-2 gap-2 mb-2 last:mb-0" key={i}>
						<a href={tempImages[i].url}>
				    		<BackgroundImage classes="w-full pb-4x3" image={tempImages[i].image} />
			    		</a>
				    	{ i + 1 < tempImages.length ? (
				    		<a href={tempImages[i + 1].url}>
				    			<BackgroundImage classes="w-full pb-4x3" image={tempImages[i + 1].image} />
			    			</a>
			    		) : ''}
				    	{ i + 2 < tempImages.length ? (
				    		<a href={tempImages[i + 2].url}>
				    			<BackgroundImage classes="w-full pb-4x3" image={tempImages[i + 2].image} />
			    			</a>
			    		) : ''}
				    	{ i + 3 < tempImages.length ? (
				    		<a href={tempImages[i + 3].url}>
				    			<BackgroundImage classes="w-full pb-4x3" image={tempImages[i + 3].image} />
			    			</a>
			    		) : ''}
					</div>
				)

				if(i + 4 < tempImages.length) {
					layout.push(
						<div className="mb-2 last:mb-0" key={i + 4}>
				    		<a href={tempImages[i + 4].url}>
								<BackgroundImage
									classes="w-[calc(100%+(theme(spacing.page-gutter)*2))] pb-4x3 -mx-page-gutter"
									image={tempImages[i + 4].image}
								/>
							</a>
						</div>
					)
				}
			}
		}
	}

	return (
		<>
			<div className="pt-header-height p-page-gutter">
				{layout}
				{/* I don't know what this div does, but the page breaks sometimes without it (when you scroll to the bottom the page hangs, only on physical mobile devices) */}
				<div class="w-full h-[4vh]"></div>
			</div>
			<Footer />
		</>
	)
}

export default SelectedWork